.partners-section {
	--swiper-wrapper-transition-timing-function: linear;
	.title {
		margin-bottom: r(60);
	}
	.partner {
		display: block;
		height: r(158);
		padding: r(20) r(30);
		border: 1px solid rgba(255, 255, 255, 0.41);
		border-radius: r(20);
		.logo {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
		}
	}
	.swiper {
		margin-top: r(24);
	}
}

@media (max-width: 1279px) {
	.partners-section {
		.title {
			margin-bottom: r(20);
		}
		.swiper {
			margin-top: r(10);
		}
		.partner {
			height: r(66);
			padding: r(10) r(13);
			border-radius: r(8);
		}
	}
}
