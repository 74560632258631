.breadcrumbs {
	.item {
		display: inline;
		font-size: r(18);
		line-height: 1.4;
		&:not(:first-child) {
			margin-left: r(13);
			&::before {
				content: "";
				display: inline-block;
				width: r(32);
				height: r(1);
				margin-right: r(13);
				vertical-align: middle;
				background: #fff;
			}
		}
		&:last-child {
			color: #908fa8;
			&::before {
				width: r(150);
				background: #908fa8;
			}
		}
	}
}

@media (max-width: 1279px) {
	.breadcrumbs {
		.item {
			font-size: r(10);
			&:not(:first-child) {
				margin-left: r(6);
				&::before {
					width: r(20);
					margin-right: r(6);
				}
			}
			&:last-child {
				&::before {
					width: r(42);
				}
			}
		}
	}
}
