.slider-arrow {
	position: relative;
	padding: r(20) r(20) r(20) 0;
	cursor: pointer;
	.icon {
		width: r(46);
		height: r(10);
		fill: #fff;
	}
	&::after {
		content: "";
		width: r(50);
		height: r(50);
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		border: 2px solid #e51a71;
		border-radius: 50%;
		transition: background-color $tr-time;
	}
	&.prev {
		transform: rotate(180deg);
	}
	&:hover {
		&::after {
			background: #e51a71;
		}
	}
}

@media (max-width: 1279px) {
	.slider-arrow {
		padding: r(12) r(14) r(12) 0;
		.icon {
			width: r(28);
			height: r(6);
		}
		&::after {
			width: r(30);
			height: r(30);
		}
	}
}
