.admin-panel {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 11;
}

@media (max-width: 1279px) {
	.admin-panel {
		display: none;
	}
}
