.person-card {
  height: 100%;
  padding: r(14);
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.41);
  border-radius: r(20);
  background: #110f1d;
  .name {
    margin-bottom: r(6);
  }
  .project {
    font-size: r(18);
    line-height: 1.4;
    color: #d3d3de;
  }
  .email {
    display: block;
    font-size: r(20);
    line-height: 1.5;
    font-weight: 400;
    color: #e51a71;
    text-decoration: underline;
    word-break: break-all;
    &:hover {
      color: #ca407b;
    }
  }
  .address {
    margin-top: r(14);
    font-size: r(20);
    line-height: 1.2;
  }
  .img-wrap {
    flex: 0 0 auto;
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;
    border-radius: r(6);
  }
  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .info {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: r(24) r(18) r(25);
  }
  .contacts {
    margin-top: auto;
    padding-top: r(24);
  }
  .person-card {
    margin-bottom: 1px;
  }
}

@media (max-width: 1279px) {
  .person-card {
    padding: r(6);
    border-radius: r(8);
    .contacts {
      padding-top: r(14);
    }
    .name {
      margin-bottom: r(4);
    }
    .project {
      font-size: r(10);
    }
    .email {
      margin-top: 0;
      font-size: r(10);
    }
    .address {
      margin-top: r(8);
      font-size: r(10);
    }
    .img-wrap {
      border-radius: r(2);
    }
    .info {
      padding: r(9) r(2) r(4);
    }
  }
}
