.text-review {
	.photo {
		width: r(200);
		height: r(200);
		margin-right: r(40);
		object-fit: cover;
		border-radius: 50%;
	}
	.text {
		margin-bottom: 0;
	}
	.position {
		margin-top: r(24);
		font-size: r(22);
		color: #908fa8;
	}
	.left-col {
		display: flex;
		align-items: center;
		align-self: center;
		flex: 0 0 40.7%;
	}
}

@media (max-width: 1279px) {
	.text-review {
		.photo {
			width: r(62);
			height: r(62);
			margin: 0 0 r(8) 0;
		}
		.position {
			margin-top: r(8);
			font-size: r(12);
		}
		.left-col {
			display: block;
		}
	}
}

@media (max-width: 767px) {
	.text-review {
		.left-col {
			flex: 0 0 auto;
		}
	}
}
