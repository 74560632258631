.content-about-project {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.img {
		max-width: 100%;
		margin: 0 auto;
		border-radius: r(20);
	}
	.img-wrap {
		flex: 0 0 100%;
		margin-bottom: r(60);
	}
	.content-text {
		flex: 0 0 57.56%;
		color: #908fa8;
	}
	.title {
		flex: 0 1 auto;
		margin-right: r(60);
	}
	.numbers {
		display: flex;
		justify-content: flex-end;
		flex: 0 0 100%;
		margin-top: r(60);
	}
	.number {
		font-size: r(20);
		line-height: 1.5;
		&:not(:last-child) {
			margin-right: r(159);
		}
	}
}

@media (max-width: 1279px) {
	.content-about-project {
		.numbers {
			margin-top: r(24);
		}
		.content-text {
			flex: 0 0 64%;
		}
		.img-wrap {
			margin-bottom: r(24);
		}
		.img {
			border-radius: r(8);
		}
		.number {
			&:not(:last-child) {
				margin: 0 r(20);
			}
		}
	}
}

@media (max-width: 767px) {
	.content-about-project {
		.numbers {
			flex-direction: column;
		}
		.title {
			margin: 0 0 r(14) 0;
		}
		.title,
		.content-text {
			flex: 0 0 100%;
		}
		.number {
			&:not(:last-child) {
				margin: 0 0 r(10) 0;
			}
		}
	}
}
