.news-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: r(57);
}

@media (max-width: 1279px) {
	.news-grid {
		gap: r(30) r(10);
	}
}

@media (max-width: 767px) {
	.news-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}
