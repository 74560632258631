.soc-icon {
	display: inline-block;
	width: r(65);
	height: r(65);
	.icon {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		transition: background-color $tr-time;
		.circle {
			fill: #e51a71;
		}
		.logo {
			fill: #fff;
		}
	}
	&:hover {
		.icon {
			background: #e51a71;
		}
	}
}

@media (max-width: 1279px) {
	.soc-icon {
		width: r(46);
		height: r(46);
	}
}
