.link-to-all {
	display: flex;
	justify-content: center;
	margin-top: r(80);
}

@media (max-width: 1279px) {
	.link-to-all {
		margin-top: r(32);
	}
}
@media (max-width: 575px) {
	.link-to-all {
		.btn {
			width: 100%;
			padding: r(8) r(35) r(10);
		}
	}
}
