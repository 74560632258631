.vacancy {
	color: #908fa8;
	&-title {
		margin-bottom: r(8) !important;
		font-size: r(22);
		color: #fff;
	}
}

@media (max-width: 1279px) {
	.vacancy {
		&-title {
			margin-bottom: r(6) !important;
		}
	}
}
