.areas-of-work {
	position: relative;
	.title {
		margin-bottom: r(20);
		font-size: r(22);
	}
	.area-name {
		text-transform: uppercase;
		font-size: r(105);
		line-height: 1;
		font-weight: 500;
		position: relative;
		.gray-text {
			color: #110f1d;
			text-shadow: -1px -1px 0 #908fa8, 1px -1px 0 #908fa8, -1px 1px 0 #908fa8, 1px 1px 0 #908fa8;
			transition: opacity $tr-time;
		}
		.color-text {
			position: absolute;
			left: 0;
			opacity: 0;
			background: linear-gradient(90deg, #e51a71 0%, #9800e7 52.08%, #5901cf 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			transition: opacity $tr-time;
		}
		&.active {
			.gray-text {
				opacity: 0;
			}
			.color-text {
				opacity: 1;
			}
		}
	}
	.container {
		display: flex;
		justify-content: flex-end;
	}
	.img {
		position: absolute;
		top: r(90);
		left: 0;
		height: r(692);
	}
	.wrap {
		padding: r(75) 0 0;
		min-height: r(692);
	}
}

@media (max-width: 1279px) {
	.areas-of-work {
		.area-name {
			font-size: r(36);
			line-height: normal;
		}
		.title {
			margin-bottom: r(20);
			font-size: r(14);
			text-align: right;
		}
		.img {
			height: r(300);
			top: r(32);
		}
		.wrap {
			min-height: r(300);
			padding: r(26) 0 0;
			margin-left: r(400);
		}
		.container {
			justify-content: flex-start;
		}
	}
}

@media (max-width: 767px) {
	.areas-of-work {
		.img {
			width: r(115);
			height: r(134);
			bottom: 0;
			right: 0;
			left: unset;
			top: unset;
		}
		.wrap {
			padding: 0 0 r(58);
			margin-left: 0;
		}
	}
}
