.seo-bg {
	position: relative;
	background: url("../images/seo-bg.png") center/cover no-repeat;
	&__bg {
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
	}
	&__content {
		position: relative;
		z-index: 1;
	}
	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		height: r(150);
		max-height: 40%;
		background: linear-gradient(180deg, rgba(17, 15, 29, 0) 0%, #110f1d 87.33%);
	}
	&::before {
		top: -1px;
		transform: rotate(180deg);
	}
	&::after {
		bottom: 0;
	}
}
