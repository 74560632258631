.team-of-professionals {
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: r(60);
  }
  .controls {
    display: flex;
    align-items: center;
  }
  .prev {
    cursor: pointer;
    .icon {
      width: r(54);
      height: r(41);
      stroke: #908fa8;
    }
  }
  .next {
    cursor: pointer;
    margin-left: r(35);
    .icon {
      width: r(153);
      height: r(41);
      stroke: #fff;
    }
  }
  .content-wrap {
    position: relative;
    overflow: hidden;
    padding: r(32) 0;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 71.35%;
      margin: auto;
      pointer-events: none;
      border-radius: r(26);
      border: 3px solid rgba(255, 255, 255, 0.15);
      background: radial-gradient(50% 50% at 50% 50%, rgba(17, 15, 29, 0) 0%, #110f1d 100%),
        url("../images/team-bg.jpeg") center/cover no-repeat;
    }
  }
  .decor-img {
    width: r(451);
    height: r(451);
    position: absolute;
    top: 0;
    bottom: 0;
    left: r(23);
    margin: auto 0;
  }
  .swiper {
    overflow: visible;
    &-col {
      width: 74.6%;
      margin-left: auto;
    }
    &-slide {
      height: auto;
    }
  }
}

@media (max-width: 1279px) {
  .team-of-professionals {
    .top {
      margin-bottom: r(20);
    }
    .content-wrap {
      padding: 0;
      &::before {
        width: 100%;
        top: r(40);
        bottom: r(40);
        border-width: 1px;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
      }
    }
    .decor-img {
      width: r(134);
      height: r(134);
    }
    .prev {
      .icon {
        width: r(27);
        height: r(20);
      }
    }
    .next {
      margin-left: r(15);
      .icon {
        width: r(76);
        height: r(20);
      }
    }
  }
}

@media (max-width: 767px) {
  .team-of-professionals {
    .controls {
      display: none;
    }
    .swiper-col {
      width: 100%;
    }
    .decor-img {
      position: static;
      margin: auto;
    }
    .swiper {
      &-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: r(10);
      }
    }
  }
}
