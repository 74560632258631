.content-text {
	font-size: r(22);
	line-height: 1.5;
	h1 {
		font-size: r(80);
	}
	h2 {
		font-size: r(62);
	}
	h3 {
		font-size: r(46);
	}
	h4 {
		font-size: r(28);
	}
	h1,
	h2,
	h3,
	h4 {
		margin: r(70) 0 r(24);
		line-height: 1.2;
		font-weight: 500;
	}
	p {
		margin-bottom: r(24);
	}
	p,
	li {
		font-size: r(22);
		line-height: 1.5;
	}
	a {
		color: #e51a71;
		text-decoration: underline;
		&:visited {
			color: #ff95c2;
		}
		&:hover {
			color: #ff4a98;
			text-decoration: none;
		}
	}
	img {
		display: block;
		margin: r(60) auto;
		max-width: 100%;
		width: auto !important;
		height: auto !important;
	}
	ul,
	ol {
		margin: r(20) 0 r(30);
		padding-left: r(24);
		img {
			display: inline-block;
			margin: 0;
			vertical-align: middle;
			border-radius: 0;
		}
	}
	li {
		color: #908fa8;
		&:not(:last-child) {
			margin-bottom: r(20);
		}
	}
	ul {
		list-style: disc;
		li {
			position: relative;
			&::marker {
				color: #e51a71;
			}
		}
	}
	ol {
		list-style: decimal outside;
	}
	.table-wrap {
		border-radius: r(20);
		border: 1px solid #908fa8;
	}
	table {
		min-width: 100%;
		border: 0;
		border-spacing: 0;
		border-collapse: collapse;
		td,
		th {
			padding: r(25) r(36);
			font-size: r(20);
			line-height: 1.5;
			border: 1px solid #908fa8;
			&:first-child {
				border-left: 0;
			}
			&:last-child {
				border-right: 0;
			}
		}
		tr {
			&:first-child {
				td {
					border-top: 0;
				}
			}
			&:last-child {
				td {
					border-bottom: 0;
				}
			}
		}
	}

	> :first-child {
		margin-top: 0;
	}
	> :last-child {
		margin-bottom: 0;
	}
}

@media (max-width: 1279px) {
	.content-text {
		font-size: r(12);
		h1 {
			font-size: r(26);
		}
		h2 {
			font-size: r(22);
		}
		h3 {
			font-size: r(14);
		}
		h1,
		h2,
		h3,
		h4 {
			margin-bottom: r(20);
		}
		p,
		li {
			font-size: r(12);
		}
		p {
			margin-bottom: r(8);
		}
		ol,
		ul {
			margin: r(8) 0;
			padding-left: r(16);
		}
		li {
			&:not(:last-child) {
				margin-bottom: r(12);
			}
		}
		table {
			td,
			th {
				padding: r(16) r(20);
				font-size: r(14);
			}
		}
		.table-wrap {
			border-radius: r(8);
		}
	}
}
