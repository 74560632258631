.news-section {
	.title {
		margin-bottom: r(60);
	}
}

@media (max-width: 1279px) {
	.news-section {
		.title {
			margin-bottom: r(20);
		}
	}
}

@media (max-width: 767px) {
	.news-section {
		.post-card {
			&:nth-child(3) {
				display: none;
			}
		}
	}
}
