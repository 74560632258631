.modal-success{
    display: flex;
    align-items: center;
    justify-content: center;
    .close{
        top: r(57);
        right: r(57);
    }
    .wrap{
        min-height: r(500);
        width: 92%;
        max-width: 870px;
        margin: 0 auto;
        padding: r(50);
        border: 2px solid rgba(#fff, .41);
        border-radius: 20px;
        overflow: hidden;
    }

    @media (max-width: 1279px){
        .wrap{
            padding: r(20);
            min-height: r(250);
        }
        .close{
            top: r(20);
            right: r(20);
        }
    }
}