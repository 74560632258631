.projects-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: r(60) r(30);
}

@media (max-width: 1279px) {
	.projects-grid {
		grid-template-columns: repeat(2, 1fr);
		gap: r(30) r(10);
	}
}
