:root {
	font-size: calcFluid(10, 15, 1280, 1920);
	--link-hover-color: #ff4a98;
	@media (max-width: 1279px) {
		font-size: 15px;
	}
	@media (min-width: 1920px) {
		font-size: 15px;
	}
}
body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	color: #fff;
	font-family: "Geologica", sans-serif;
	font-weight: 300;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #110f1d;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 1;
		background: url("../images/body-bg.png") 0 -52%/100% auto no-repeat;
		opacity: 0.5;
		pointer-events: none;
	}
}
main {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
*,
::before,
::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
ol,
ul {
	list-style: none;
}
button {
	border: 0;
	color: inherit;
	background: transparent;
	cursor: pointer;
}
button,
input,
textarea {
	font-family: inherit;
}
input {
	min-width: 0;
	font-size: inherit;
	border-radius: 0;
}
input[type="file" i],
input[type="file" i]::-webkit-file-upload-button {
	cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type="number"] {
	-webkit-appearance: none;
	-moz-appearance: textfield;
	appearance: none;
}
a {
	color: inherit;
	text-decoration: none;
	transition: color $tr-time;
	&:hover {
		color: var(--link-hover-color);
		text-decoration: none;
	}
}
svg {
	vertical-align: middle;
	transition: fill $tr-time, stroke $tr-time;
}
img,
iframe,
svg,
picture {
	display: block;
}
iframe {
	border: 0;
}
h1,
h2,
h3 {
	font-weight: 400;
	font-size: inherit;
}
:focus {
	outline: none;
}
.top-pixel {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: r(250);
	pointer-events: none;
}

@media (max-width: 1279px) {
	.top-pixel {
		height: r(100);
	}
}
