.main-screen {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: r(150) 0 r(65);
	margin-bottom: r(90);
	position: relative;
	min-height: r(726);
	.title {
		font-size: r(80);
		font-weight: 500;
	}
	.title-wrap {
		flex: 0 0 53%;
		.bg {
			display: none;
		}
	}
	.text-wrap {
		flex: 0 0 25.6%;
		padding: r(25) 0 0 0;
		margin-right: r(119);
	}
	.text {
		margin-bottom: r(34);
		font-size: r(20);
		line-height: 1.5;
	}
	.container {
		display: flex;
		justify-content: space-between;
	}
	.bg__img {
		object-position: bottom;
	}
}

@media (max-width: 1279px) {
	.main-screen {
		margin-bottom: r(34);
		padding: 0;
		min-height: 0;
		.bg {
			display: none;
		}
		.container {
			display: block;
		}
		.title {
			font-size: r(26);
		}
		.title-wrap {
			position: relative;
			padding: r(103) 21px r(20);
			margin: 0 -21px;
			.bg {
				display: block;
				border-radius: 0 0 r(40) r(40);
				overflow: hidden;
			}
		}
		.text-wrap {
			margin-right: 0;
			padding: r(20) 0 0;
		}
		.text {
			margin-bottom: r(20);
			font-size: r(12);
		}
	}
}

@media (max-width: 575px) {
	.main-screen {
		.link {
			width: 100%;
		}
	}
}
