.implemented-projects {
	.title {
		width: 75%;
		grid-column: span 2;
	}
	.container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: r(30);
	}
}

.implemented-project {
	position: relative;
	padding: r(30);
	border-radius: r(26);
	border: 1px solid rgba(255, 255, 255, 0.41);
	background: rgba(255, 255, 255, 0.02);
	overflow: hidden;
	.name {
		margin-bottom: r(33);
		font-size: r(22);
	}
	.number {
		font-size: r(56);
		font-weight: 500;
	}
	.icon {
		position: absolute;
		top: r(30);
		right: r(30);
	}
	.circle {
		position: absolute;
		z-index: -1;
		border-radius: 50%;
		transition: transform $tr-time;
	}
	.circle1 {
		width: r(299);
		height: r(299);
		right: r(-104);
		bottom: r(-137);
		background: radial-gradient(50% 50% at 50% 50%, #302d6f 0%, rgba(48, 45, 111, 0) 100%);
	}
	.circle2 {
		width: r(379);
		height: r(379);
		top: r(-200);
		left: r(-184);
		background: radial-gradient(50% 50% at 50% 50%, rgba(178, 0, 242, 0.51) 0%, rgba(178, 0, 242, 0) 100%);
	}
	.circle3 {
		width: r(245);
		height: r(245);
		bottom: r(-73);
		left: r(-80);
		background: radial-gradient(50% 50% at 50% 50%, #302d6f 0%, rgba(48, 45, 111, 0) 100%);
	}
	&:not(:hover) {
		.circle {
			transform: scale(0);
		}
	}
}

@media (max-width: 1279px) {
	.implemented-projects {
		.title {
			width: 100%;
		}
		.container {
			gap: r(10);
		}
	}
	.implemented-project {
		padding: r(8) r(10) r(11);
		border-radius: r(8);
		.name {
			margin-bottom: r(13);
			font-size: r(10);
		}
		.number {
			font-size: r(20);
		}
		.icon {
			width: r(39);
			height: r(39);
			top: r(15);
			right: r(7);
			object-fit: contain;
		}
	}
}

@media (max-width: 767px) {
	.implemented-projects {
		.container {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}
