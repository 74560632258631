.not-found-page {
	.title {
		font-size: r(244);
	}
	.container {
		align-items: flex-end;
	}
	.text-wrap {
		padding-bottom: r(20);
	}
}

@media (max-width: 1279px) {
	.not-found-page {
		.title {
			font-size: r(146);
		}
	}
}
