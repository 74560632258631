.projects-section {
	.title {
		margin-bottom: r(60);
	}
	.wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: r(-15) 0;
	}
	.project {
		display: flex;
		position: relative;
		flex: 0 1 auto;
		margin: r(15) 0;
		padding: r(45) r(40) r(40);
		z-index: 1;
		background: #e51a71;
		border-radius: r(20);
		overflow: hidden;
		.link-cover {
			&:hover {
				color: inherit;
			}
		}
		&:nth-child(1) {
			flex-basis: 57.56%;
			height: r(420);
			.text-wrap {
				flex-direction: row;
				.text {
					padding: r(15) 0 0 r(15);
					flex: 0 0 r(250);
				}
			}
		}
		&:nth-child(2) {
			flex-basis: 40.59%;
			height: r(260);
			.text {
				width: 50%;
			}
		}
		&:nth-child(3) {
			.text {
				width: 67%;
			}
		}
		&:nth-child(5) {
			flex-basis: 40.59%;
			margin-top: r(-145);
			color: #110f1d;
			.name {
				width: 70%;
			}
			.text {
				width: 70%;
			}
		}
		&:nth-child(6) {
			flex-basis: 40.59%;
			height: r(420);
			.name {
				width: 75%;
			}
			.text {
				width: 71.5%;
			}
		}
		&:nth-child(4),
		&:nth-child(3),
		&:nth-child(7),
		&:nth-child(8) {
			flex-basis: 27.87%;
			height: r(420);
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border-radius: r(20);
			border: 1px solid rgba(#fff, 0.4);
			pointer-events: none;
		}
		.text {
			font-size: r(18);
			line-height: 1.4;
		}
		.text-wrap {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.text {
				margin-top: r(15);
			}
		}
	}
}

@media (max-width: 1279px) {
	.projects-section {
		.wrap {
			margin: r(-5) 0;
		}
		.title {
			margin-bottom: r(20);
		}
		.project {
			margin: r(5) 0;
			padding: r(12) r(9);
			border-radius: r(8);
			&::before {
				content: "";
				border-radius: r(8);
			}
			.text {
				font-size: r(10);
			}
			.text-wrap {
				justify-content: flex-start;
				.text {
					margin-top: r(10);
				}
			}
			.name {
				hyphens: auto;
			}
		}
	}
}

@media (max-width: 1279px) {
	.projects-section {
		.project {
			&:nth-child(1) {
				height: r(200);
			}
			&:nth-child(2) {
				height: r(140);
			}
			&:nth-child(5) {
				margin-top: r(-50);
			}
			&:nth-child(4),
			&:nth-child(3),
			&:nth-child(7),
			&:nth-child(6),
			&:nth-child(8) {
				height: r(160);
			}
		}
	}
}

@media (max-width: 991px) {
	.projects-section {
		.project {
			&:nth-child(1) {
				flex-basis: 100%;
				height: r(134);
				.text-wrap {
					flex-direction: column;
					justify-content: flex-start;
					.text {
						flex: 0 0 auto;
						width: 45%;
						padding: 0;
						margin: r(10) r(18) 0 auto;
					}
				}
			}
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(7),
			&:nth-child(8) {
				flex-basis: calc(50% - r(5));
				height: r(276);
				.text {
					width: 100%;
				}
			}
			&:nth-child(4) {
				order: 1;
			}
			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8) {
				order: 2;
			}
			&:nth-child(5) {
				margin-top: r(5);
				.name {
					width: 100%;
				}
			}
			&:nth-child(6) {
				flex-basis: 100%;
				height: r(134);
				.name {
					width: 100%;
				}
				.text {
					width: 90%;
				}
			}
		}
	}
}
