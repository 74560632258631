.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: r(60);
	.pages {
		display: flex;
		align-items: center;
		margin: 0 r(30);
	}
	.page-num {
		position: relative;
		margin: 0 r(12);
		font-size: r(22);
		font-weight: 500;
		line-height: 1.5;
		&.current {
			&::before {
				content: "";
				width: r(36);
				height: r(36);
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: -1;
				transform: translate(-50%, -50%);
				background: #e51a71;
				border-radius: 50%;
			}
		}
	}
}

@media (max-width: 1279px) {
	.pagination {
		margin-top: r(30);
		.pages {
			margin: 0 r(20);
		}
		.page-num {
			margin: 0 r(7);
			font-size: r(12);
			&.current {
				&::before {
					width: r(20);
					height: r(20);
				}
			}
		}
	}
}
