.vk-section{
    padding: r(100) 0;

    .container{
        display: grid;
        grid-template-columns: 50% 25%;
        justify-content: space-between;
    }

    @media (max-width: 767px){
        .container{
            gap: 30px;
            grid-template-columns: 100%;
        }
    }
}