.feedback-section {
	padding: r(126) 0;
	margin-top: r(90);
	position: relative;
	--form-border-color: rgba(255, 255, 255, 0.41);
	--form-placeholder-color: #fff;
	--form-text-color: #fff;
	--form-personal-color: #908fa8;
	--form-personal-link-color: #fff;
	--form-status-icon-color: #fff;
	--form-error-color: red;
	--form-status-scale: 0.67;
	.text {
		width: 41.75%;
		margin-top: r(24);
		font-size: r(20);
		line-height: 1.2;
	}
	.wrap {
		width: 70%;
		margin: 0 auto;
	}
	.personal {
		margin-top: r(24);
	}
	.submit{
		margin-top: r(20);
	}
}

@media (max-width: 1279px) {
	.feedback-section {
		padding: r(55) 0;
		margin-top: r(32);
		--form-status-scale: 0.52;
		.wrap {
			width: 100%;
		}
		.text {
			width: 100%;
			margin-top: r(20);
			font-size: r(12);
			line-height: 1.5;
		}
		.personal {
			margin-top: r(20);
		}
		.form {
			margin-top: r(35);
		}
	}
}

@media (max-width: 575px) {
	.feedback-section {
		.submit {
			width: 100%;
		}
		.personal {
			text-align: center;
		}
	}
}
