.post-card {
	position: relative;
	.img-wrap {
		position: relative;
		padding-bottom: 75%;
		border-radius: r(20);
		overflow: hidden;
		background: #e51a71;
	}
	.img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.info {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: r(32) r(20) 0;
	}
	.name {
		margin-top: r(20);
		flex: 0 0 100%;
	}
	.section {
		padding: r(4) r(14);
		color: #6a72fd;
		font-size: r(18);
		line-height: 1.4;
		border-radius: r(10);
		border: 1px solid #6a72fd;
	}
	.date {
		align-self: center;
		color: #6a72fd;
		font-size: r(18);
	}
	&.alt {
		.name {
			margin-top: 0;
		}
		.info {
			padding: r(20) 0 0;
		}
		.text {
			margin-top: r(14);
			color: #908fa8;
			font-size: r(18);
			line-height: 1.4;
		}
	}
}

@media (max-width: 1279px) {
	.post-card {
		.info {
			padding: r(8) 0 0 !important;
		}
		.img-wrap {
			border-radius: r(8);
		}
		.section {
			padding: r(3);
			font-size: r(10);
		}
		.date {
			flex: 0 0 100%;
			margin-top: r(8);
			font-size: r(10);
		}
		.name {
			margin-top: r(8);
		}
		&.alt {
			.text {
				margin-top: r(8);
				font-size: r(10);
			}
		}
	}
}

@media (max-width: 767px) {
	.post-card {
		.img-wrap {
			padding-bottom: 100%;
		}
	}
}
