.stages-of-work {
	overflow: hidden;
	position: relative;
	&::after {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 31.7%;
		z-index: 1;
		background: linear-gradient(90deg, rgba(17, 15, 29, 0) 0%, #110f1d 140.05%);
		pointer-events: none;
	}
	.top {
		display: flex;
		justify-content: space-between;
		margin-bottom: r(60);
	}
	.controls {
		display: flex;
		align-items: center;
	}
	.prev {
		cursor: pointer;
		.icon {
			width: r(54);
			height: r(41);
			stroke: #908fa8;
		}
	}
	.next {
		cursor: pointer;
		margin-left: r(35);
		.icon {
			width: r(153);
			height: r(41);
			stroke: #fff;
		}
	}
	.slider-wrap {
		width: 34.8%;
		margin-left: r(47);
	}
	.swiper {
		overflow: visible;
		.number {
			position: absolute;
			top: 0;
			left: 0;
			background: #110f1d;
			font-size: r(56);
			font-weight: 500;
			color: #e51a71;
			transform: translateX(-50%);
			transition-property: color, text-shadow;
			transition-duration: $tr-time;
		}
		.text {
			margin: r(22) 0 0 r(38);
			font-size: r(20);
			line-height: 1.2;
			color: #cbcbcb;
		}
		.text,
		.name {
			transition: color $tr-time;
		}
		.circle {
			width: r(60);
			height: r(60);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			transform: translate(-50%, 50%);
			circle {
				transition-duration: $tr-time;
				transition-property: fill, stroke;
			}
		}
		&-slide {
			height: auto;
			padding: r(13) r(104) r(67) r(66);
			border-left: 1px dashed #fff;
			border-bottom: 2px solid #908fa8;
			&::after {
				content: "";
				width: 100%;
				position: absolute;
				bottom: -2px;
				left: 0;
				height: 2px;
				transform: scaleX(0);
				transform-origin: left top;
				transition: transform 0.5s;
				background: #fff;
			}
			&:not(.swiper-slide-active) {
				.number {
					color: #110f1d;
					text-shadow: -1px -1px 0 #e51a71, 1px -1px 0 #e51a71, -1px 1px 0 #e51a71, 1px 1px 0 #e51a71;
				}
				.text,
				.name {
					color: #908fa8;
				}
			}
			&.swiper-slide-active {
				.circle {
					circle {
						stroke: #fff;
						fill: #e51a71;
					}
				}
				&::after {
					transition-delay: 0.5s;
					transform: scaleX(0.5);
				}
			}
			&.swiper-slide-prev {
				&::after {
					transform: scaleX(1);
				}
			}
		}
	}
}

@media (max-width: 1279px) {
	.stages-of-work {
		.top {
			margin-bottom: r(44);
		}
		.controls {
			margin-top: r(16);
		}
		.slider-wrap {
			width: 34%;
			margin-left: r(12);
		}
		.swiper {
			&-slide {
				padding: r(6) r(42) r(30) r(26);
			}
			.circle {
				width: r(24);
				height: r(24);
			}
			.text {
				margin: r(9) 0 0 r(16);
				font-size: r(10);
			}
			.number {
				font-size: r(22);
			}
			.name {
				font-size: r(18);
			}
		}
		.prev {
			.icon {
				width: r(27);
				height: r(20);
			}
		}
		.next {
			margin-left: r(15);
			.icon {
				width: r(76);
				height: r(20);
			}
		}
	}
}

@media (max-width: 991px) {
	.stages-of-work {
		.slider-wrap {
			width: 45%;
		}
	}
}

@media (max-width: 575px) {
	.stages-of-work {
		.top {
			flex-direction: column;
		}
		.controls {
			margin-top: r(16);
		}
		.slider-wrap {
			width: 82.4%;
		}
	}
}
