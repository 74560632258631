.sidebar {
	flex: 0 0 r(390);
	padding: r(48) r(30) r(40);
	border-radius: r(26);
	border: 1px solid rgba(255, 255, 255, 0.41);
	background: rgba(255, 255, 255, 0.02);
	&-title {
		margin-bottom: r(30);
		font-size: r(22);
	}
}
