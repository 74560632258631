.project-card {
	position: relative;
	.img-wrap {
		position: relative;
		padding-bottom: 56.55%;
		margin-bottom: r(14);
		border-radius: r(20) r(20) 0 0;
		overflow: hidden;
		.customer {
			position: absolute;
			top: r(24);
			left: r(33);
			z-index: 1;
			color: #fff;
		}
		.shadow {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(#000, 0.4);
		}
	}
	.img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
	}
	.name {
		margin-bottom: r(13);
	}
	.progress {
		position: relative;
		margin-bottom: r(40);
		border-radius: r(20);
		border: 1px solid rgba(255, 255, 255, 0.41);
		.value {
			position: relative;
			z-index: 1;
			text-align: center;
			font-size: r(20);
		}
		&::before {
			content: "";
			width: calc(var(--value) * 1%);
			position: absolute;
			top: -1px;
			left: -1px;
			bottom: -1px;
			border-radius: r(20);
			background: linear-gradient(90deg, #ca0056 1.41%, #ef0167 98.43%);
		}
	}
	.customer {
		font-size: r(18);
		line-height: 1.4;
		color: #d3d3de;
	}
	.desc {
		color: #908fa8;
	}
	.wrap {
		padding: 0 r(69) 0 r(33);
	}
	&.alt {
		.img-wrap {
			margin-bottom: r(24);
		}
		.name {
			margin-bottom: r(24);
		}
	}
}

@media (max-width: 1279px) {
	.project-card {
		width: 78%;
		margin: 0 auto;
		.img-wrap {
			margin-bottom: r(8) !important;
			border-radius: r(8) r(8) 0px 0px;
			.shadow,
			.customer {
				display: none;
			}
		}
		.customer {
			font-size: r(10);
		}
		.progress {
			margin-bottom: r(9);
			border-radius: r(7);
			.value {
				font-size: r(10);
				line-height: 1;
			}
		}
		.wrap {
			padding: 0 r(11) 0 r(7);
		}
		.name {
			margin-bottom: r(8) !important;
			font-size: r(14);
		}
	}
}
