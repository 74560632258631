.slider-pagination {
	display: flex;
	justify-content: center;
	.swiper-pagination-bullet {
		width: r(14);
		height: r(14);
		border-radius: 50%;
		border: 1px solid #fff;
		cursor: pointer;
		&-active {
			border-color: rgba(255, 255, 255, 0.41);
			background: linear-gradient(90deg, #e51a71 11.94%, #9800e7 100%);
		}
		&:not(:last-child) {
			margin-right: r(12);
		}
	}
}

@media (max-width: 1279px) {
	.slider-pagination {
		.swiper-pagination-bullet {
			width: r(10);
			height: r(10);
			&:not(:last-child) {
				margin-right: r(6);
			}
		}
	}
}
