.categories-section {
	.title {
		margin-bottom: r(60);
	}
	.grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: r(60) r(30);
	}
	.category {
		position: relative;
		.img-wrap {
			position: relative;
			padding-bottom: 73%;
			border-radius: r(20);
			overflow: hidden;
			background: #e51a71;
		}
		.img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.name {
			margin-top: r(20);
		}
	}
}

@media (max-width: 1279px) {
	.categories-section {
		.title {
			margin-bottom: r(20);
		}
		.grid {
			grid-template-columns: repeat(2, 1fr);
			gap: r(30) r(10);
		}
		.category {
			.img-wrap {
				border-radius: r(8);
			}
			.name {
				margin-top: r(8);
				font-size: r(14);
			}
		}
	}
}
