.content-slider {
	.img-wrap {
		border-radius: r(20);
	}
	.main-swiper {
		.img-wrap {
			display: block;
			position: relative;
			padding-bottom: 55.9%;
			overflow: hidden;
		}
		.img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.thumbs-swiper {
		.img-wrap {
			position: relative;
			padding-bottom: 51.3%;
			overflow: hidden;
			.img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

@media (max-width: 1279px) {
	.content-slider {
		.img-wrap {
			border-radius: r(8);
		}
	}
}
