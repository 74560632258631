.categories-sidebar {
	align-self: start;
	border-radius: r(26);
	border: 1px solid rgba(255, 255, 255, 0.41);
	background: rgba(255, 255, 255, 0.02);
	.group {
		padding: r(34) r(30) r(40);
		&-name {
			margin-bottom: r(30);
			font-size: r(22);
		}
		&:not(:last-child) {
			border-bottom: 1px solid rgba(255, 255, 255, 0.41);
		}
	}
	.link {
		display: block;
		padding-left: r(14);
		color: #908fa8;
		font-size: r(20);
		line-height: 1.5;
		&:not(:last-child) {
			margin-bottom: r(22);
		}
		&.active {
			color: #e51a71;
		}
	}
}
