.projects-page {
	display: grid;
	gap: r(30);
	grid-template-columns: 23.65% 1fr;
}

@media (max-width: 1279px) {
	.projects-page {
		grid-template-columns: 1fr;
	}
}
