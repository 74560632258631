.form {
	&-input {
		width: 100%;
		height: r(64);
		padding: r(17) r(30) 0;
		font-size: r(22);
		line-height: 1.2;
		color: var(--form-text-color);
		background: transparent;
		border: 2px solid var(--form-border-color);
		border-radius: r(26);
		transition: border-color $tr-time;
		&::placeholder {
			opacity: 0;
		}
		&.error {
			border-color: var(--form-error-color) !important;
		}
		&:focus,
		&:not(:placeholder-shown) {
			+ .form-placeholder {
				transform: translateY(-50%) scale(0.6);
				color: var(--form-placeholder-active-color);
			}
		}
		&.no-placeholder {
			padding-top: 0;
		}
		&.textarea{
			resize: none;
			height: 100%;
			+ .form-placeholder{
				margin-top: r(17);
			}
		}
	}
	&-label {
		display: block;
		&-text {
			display: block;
			margin-bottom: r(14);
			color: var(--form-text-color);
		}
	}
	&-placeholder {
		position: absolute;
		top: 0;
		bottom: 0;
		left: r(30);
		height: 1em;
		margin: auto;
		color: var(--form-placeholder-color);
		font-size: r(22);
		line-height: 1;
		pointer-events: none;
		transform-origin: left top;
		transition-property: transform;
		transition-duration: $tr-time;
	}
	&-input-wrap {
		position: relative;
	}
	&-error {
		margin-top: r(8);
		font-size: r(14);
		line-height: 1.39;
		color: var(--form-error-color);
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-text-fill-color: var(--form-text-color);
	-webkit-box-shadow: 0 0 0px 40rem #110f1d inset;
}

input:-webkit-autofill {
	background-color: #faffbd !important;
}

.personal {
	font-size: r(18);
	line-height: 1.3;
	color: var(--form-personal-color);
	&__link {
		color: var(--form-personal-link-color);
		text-decoration: underline;
	}
}

.ok-icon {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: r(42);
	height: r(29);
	margin: auto;
	transform: scale(var(--form-status-scale));
	fill: var(--form-status-icon-color);
}

@media (max-width: 1279px) {
	.personal {
		font-size: r(10);
	}
	.form {
		&-input {
			padding: r(8) r(16) 0;
			height: r(40);
			font-size: r(12);
		}
		&-placeholder {
			left: r(16);
			font-size: r(12);
		}
	}
}
