.cases-section {
	overflow: hidden;
	.title {
		margin-bottom: r(60);
	}
	.swiper {
		overflow: visible;
	}
	.slider-arrow {
		position: absolute;
		top: 50%;
		z-index: 3;
		margin: auto 0;
		&.prev {
			left: r(-14);
		}
		&.next {
			right: r(-14);
		}
	}
}

@media (min-width: 1280px) {
	.cases-section {
		.swiper-wrapper {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: r(80) r(90);
		}
		.slider-arrow {
			display: none;
		}
	}
}

@media (max-width: 1279px) {
	.cases-section {
		.title {
			margin-bottom: r(20);
		}
	}
}
