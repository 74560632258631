.content-block {
	&:not(:last-child) {
		margin-bottom: r(90);
	}
}

@media (max-width: 1279px) {
	.content-block {
		&:not(:last-child) {
			margin-bottom: r(64);
		}
	}
}
