*,
::before,
::after {
    --un-rotate: 0;
    --un-rotate-x: 0;
    --un-rotate-y: 0;
    --un-rotate-z: 0;
    --un-scale-x: 1;
    --un-scale-y: 1;
    --un-scale-z: 1;
    --un-skew-x: 0;
    --un-skew-y: 0;
    --un-translate-x: 0;
    --un-translate-y: 0;
    --un-translate-z: 0;
    --un-pan-x: ;
    --un-pan-y: ;
    --un-pinch-zoom: ;
    --un-scroll-snap-strictness: proximity;
    --un-ordinal: ;
    --un-slashed-zero: ;
    --un-numeric-figure: ;
    --un-numeric-spacing: ;
    --un-numeric-fraction: ;
    --un-border-spacing-x: 0;
    --un-border-spacing-y: 0;
    --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
    --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
    --un-shadow-inset: ;
    --un-shadow: 0 0 rgb(0 0 0 / 0);
    --un-ring-inset: ;
    --un-ring-offset-width: 0px;
    --un-ring-offset-color: #fff;
    --un-ring-width: 0px;
    --un-ring-color: rgb(147 197 253 / 0.5);
    --un-blur: ;
    --un-brightness: ;
    --un-contrast: ;
    --un-drop-shadow: ;
    --un-grayscale: ;
    --un-hue-rotate: ;
    --un-invert: ;
    --un-saturate: ;
    --un-sepia: ;
    --un-backdrop-blur: ;
    --un-backdrop-brightness: ;
    --un-backdrop-contrast: ;
    --un-backdrop-grayscale: ;
    --un-backdrop-hue-rotate: ;
    --un-backdrop-invert: ;
    --un-backdrop-opacity: ;
    --un-backdrop-saturate: ;
    --un-backdrop-sepia: ;
}

::backdrop {
    --un-rotate: 0;
    --un-rotate-x: 0;
    --un-rotate-y: 0;
    --un-rotate-z: 0;
    --un-scale-x: 1;
    --un-scale-y: 1;
    --un-scale-z: 1;
    --un-skew-x: 0;
    --un-skew-y: 0;
    --un-translate-x: 0;
    --un-translate-y: 0;
    --un-translate-z: 0;
    --un-pan-x: ;
    --un-pan-y: ;
    --un-pinch-zoom: ;
    --un-scroll-snap-strictness: proximity;
    --un-ordinal: ;
    --un-slashed-zero: ;
    --un-numeric-figure: ;
    --un-numeric-spacing: ;
    --un-numeric-fraction: ;
    --un-border-spacing-x: 0;
    --un-border-spacing-y: 0;
    --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
    --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
    --un-shadow-inset: ;
    --un-shadow: 0 0 rgb(0 0 0 / 0);
    --un-ring-inset: ;
    --un-ring-offset-width: 0px;
    --un-ring-offset-color: #fff;
    --un-ring-width: 0px;
    --un-ring-color: rgb(147 197 253 / 0.5);
    --un-blur: ;
    --un-brightness: ;
    --un-contrast: ;
    --un-drop-shadow: ;
    --un-grayscale: ;
    --un-hue-rotate: ;
    --un-invert: ;
    --un-saturate: ;
    --un-sepia: ;
    --un-backdrop-blur: ;
    --un-backdrop-brightness: ;
    --un-backdrop-contrast: ;
    --un-backdrop-grayscale: ;
    --un-backdrop-hue-rotate: ;
    --un-backdrop-invert: ;
    --un-backdrop-opacity: ;
    --un-backdrop-saturate: ;
    --un-backdrop-sepia: ;
}

.container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 88.15%;
    padding-left: 21px;
    padding-right: 21px;
}

@media (max-width: 1279.9px) {
    .container {
        max-width: 100%;
    }
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.bottom-0 {
    bottom: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.top-44\% {
    top: 44%;
}

.grid {
    display: grid;
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.mx-\[\.5rem\] {
    margin-left: .5rem;
    margin-right: .5rem;
}

.mb-\[1\.33rem\] {
    margin-bottom: 1.33rem;
}

.mt-\[1rem\] {
    margin-top: 1rem;
}

.mt-\[2rem\] {
    margin-top: 2rem;
}

.block {
    display: block;
}

.hidden {
    display: none;
}

.w-\[60\%\] {
    width: 60%;
}

.w-full {
    width: 100%;
}

.flex {
    display: flex;
}

.shrink-0 {
    flex-shrink: 0;
}

.grow-1 {
    flex-grow: 1;
}

.basis-auto {
    flex-basis: auto;
}

.items-start {
    align-items: flex-start;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.gap-\[5rem\] {
    gap: 5rem;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.from-\[\#110F1D\] {
    --un-gradient-from-position: 0%;
    --un-gradient-from: rgb(17 15 29 / var(--un-from-opacity, 1)) var(--un-gradient-from-position);
    --un-gradient-to-position: 100%;
    --un-gradient-to: rgb(17 15 29 / 0) var(--un-gradient-to-position);
    --un-gradient-stops: var(--un-gradient-from), var(--un-gradient-to);
}

.from-53\.65\% {
    --un-gradient-from-position: 53.65%;
}

.bg-gradient-to-t {
    --un-gradient-shape: to top;
    --un-gradient: var(--un-gradient-shape), var(--un-gradient-stops);
    background-image: linear-gradient(var(--un-gradient));
}

.pb-\[2\.1rem\] {
    padding-bottom: 2.1rem;
}

.pt-\[4\.27rem\] {
    padding-top: 4.27rem;
}

.text-wrap {
    text-wrap: wrap;
}

@media (max-width: 1279.9px) {
    .max-xl\:absolute {
        position: absolute;
    }

    .max-xl\:hidden {
        display: none;
    }

    .max-xl\:h-\[23rem\] {
        height: 23rem;
    }
}

@media (min-width: 1280px) {
    .xl\:mx-\[2rem\] {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .xl\:mb-\[2\.4rem\] {
        margin-bottom: 2.4rem;
    }

    .xl\:mb-\[4rem\] {
        margin-bottom: 4rem;
    }

    .xl\:mt-\[2\.6rem\] {
        margin-top: 2.6rem;
    }

    .xl\:mt-\[4rem\] {
        margin-top: 4rem;
    }

    .xl\:hidden {
        display: none;
    }

    .xl\:w-\[68\.5\%\] {
        width: 68.5%;
    }

    .xl\:w-\[74\.6\%\] {
        width: 74.6%;
    }

    .xl\:flex {
        display: flex;
    }

    .xl\:gap-\[8rem\] {
        gap: 8rem;
    }

    .xl\:pb-\[6rem\] {
        padding-bottom: 6rem;
    }

    .xl\:pt-\[12rem\] {
        padding-top: 12rem;
    }
}
.bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
	pointer-events: none;
	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.collapse {
	overflow: hidden;
	transition: height var(--collapse-time, #{$tr-time * 2});
	&.is-collapsed {
		height: 0;
	}
}

.link-cover {
	&::before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 1;
	padding: r(16);
	text-align: center;
	font-size: r(22);
	line-height: 1.5;
	font-weight: 500;
	color: var(--text-color) !important;
	text-decoration: none !important;
	border-radius: r(26);
	border: 2px solid var(--border-color);
	background: var(--bg-color);
	cursor: pointer;
	overflow: hidden;
	&-large {
		padding: r(13) r(40) r(14);
	}
	&-color {
		--text-color: #fff;
		--border-color: rgba(255, 255, 255, 0.41);
		--bg-color: #e51a71;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			background: linear-gradient(90deg, #e51a71 11.94%, #9800e7 100%);

			transition: opacity $tr-time;
		}
		&:hover {
			&::before {
				opacity: 0;
			}
		}
	}
	&-gray-border {
		--text-color: #fff;
		--bg-color: transparent;
		--border-color: rgba(255, 255, 255, 0.41);
		&:hover {
			--bg-color: #e51a71;
		}
	}
	&-pink-border {
		--text-color: #fff;
		--bg-color: transparent;
		--border-color: #e51a71;
		--arr-color: #fff;
		&:hover {
			--bg-color: #e51a71;
		}
	}
	&__link-arr {
		position: relative;
		margin-right: r(30);
		.icon {
			width: r(23);
			height: r(11);
			fill: var(--arr-color);
		}
		&::after {
			content: "";
			display: block;
			width: r(24);
			height: r(24);
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(45%, -50%);
			border: 1px solid var(--arr-color);
			border-radius: 50%;
		}
	}
	.preloader,
	.ok-icon {
		display: none;
	}
	&:not(:disabled) {
		transition-duration: $tr-time;
		transition-property: color, border-color, background-color, box-shadow;
	}
	&:disabled,
	&.htmx-request,
	&.success {
		pointer-events: none;
		transition-duration: 0ms;
		--text-color: transparent;
	}
	&.htmx-request {
		.preloader {
			display: block;
		}
	}
	&.success {
		.ok-icon {
			display: block;
		}
	}
}

.h1 {
	font-size: r(80);
	font-weight: 500;
}

.h2 {
	font-size: r(62);
	font-weight: 500;
	line-height: 1.2;
}

.h3 {
	font-size: r(46);
	font-weight: 500;
	line-height: 1.3;
}

.h4 {
	font-size: r(28);
	line-height: 1.3;
	font-weight: 500;
}

@media (max-width: 1279px) {
	.h1 {
		font-size: r(26);
	}
	.h2 {
		font-size: r(22);
	}
	.h3 {
		font-size: r(14);
	}
	.h4 {
		font-size: r(12);
	}
	.btn {
		padding: r(8) r(16) r(10);
		font-size: r(12);
		&-large {
			padding: r(8) r(16) r(10);
		}
		&__link-arr {
			margin-right: r(20);
			.icon {
				width: r(14);
				height: r(6);
			}
			&::after {
				width: r(16);
				height: r(16);
			}
		}
	}
}
