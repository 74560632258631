.footer {
	position: relative;
	background: #110f1d;
	.video-link {
		position: fixed;
		bottom: 5%;
		left: r(36);
		z-index: 5;
		.icon {
			width: r(99);
			height: r(99);
		}
	}
	.top {
		padding: r(60) 0 r(67);
	}
	.container {
		display: flex;
		justify-content: space-between;
	}
	.logo {
		width: r(200);
		height: r(200);
		flex: 0 0 auto;
		position: relative;
		z-index: 1;
		.icon {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		&::before {
			content: "";
			width: 132%;
			height: 132%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0);
			z-index: -1;
			background: radial-gradient(50% 50% at 50% 50%, rgba(229, 26, 113, 0.85) 0%, rgba(229, 26, 113, 0) 100%);
			transition: transform $tr-time;
		}
		&:hover {
			&::before {
				transform: translate(-50%, -50%);
			}
		}
	}
	.contacts-col {
		display: flex;
		flex-direction: column;
		.links {
			display: grid;
			grid-auto-flow: column;
			grid-auto-columns: max-content;
			gap: r(18);
			justify-content: flex-end;
			margin-top: auto;
		}
		.tel {
			font-size: r(36);
			font-weight: 500;
			text-align: right;
			&-text {
				font-size: r(16);
				text-align: right;
				color: #908fa8;
			}
		}
	}
	.menus {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: max-content;
		align-items: flex-start;
		gap: r(107);
	}
	.menu {
		.item {
			margin-bottom: r(16);
			font-size: r(18);
			line-height: 1.2;
			color: #908fa8;
			&:not(:first-child) {
				--link-hover-color: #d3d3de;
			}
			&:first-child {
				margin-bottom: r(26);
				font-size: r(22);
				color: #fff;
				font-weight: 500;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.menu2 {
		.item {
			margin-bottom: r(26);
			font-size: r(22);
			color: #fff;
			font-weight: 500;
		}
	}
	.bottom {
		padding: r(20) 0 r(21);
		border-top: 2px solid #908fa8;
		.text {
			color: #908fa8;
			font-size: r(16);
			line-height: 1.4;
		}
		.ps-link {
			display: flex;
			.icon {
				width: r(123);
				height: r(25);
				margin-left: r(8);
				fill: #908fa8;
			}
		}
	}
}

@media (max-width: 1279px) {
	.footer {
		.logo {
			width: r(120);
			height: r(120);
		}
		.video-link {
			left: r(9);
			.icon {
				width: r(40);
				height: r(40);
			}
		}
		.menus {
			gap: r(40);
		}
		.menu {
			.item {
				font-size: r(12);
				&:first-child {
					font-size: r(14);
				}
				br {
					display: none;
				}
			}
		}
		.contacts-col {
			.tel {
				font-size: r(22);
				text-align: left;
				&-text {
					font-size: r(12);
					text-align: left;
				}
			}
			.links {
				margin-top: r(40);
				justify-content: flex-start;
			}
		}
		.bottom {
			.text {
				font-size: r(10);
			}
			.ps-link {
				.icon {
					width: r(68);
					height: r(14);
					margin-left: r(3);
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.footer {
		.top {
			padding: r(64) 0 r(20);
			.container {
				flex-direction: column;
			}
		}
		.logo {
			margin: 0 auto;
		}
		.menus {
			margin: r(40) 0;
		}
		.menu2 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: r(12);
			.item {
				margin-bottom: 0 !important;
			}
		}
	}
}

@media (max-width: 767px) {
	.footer {
		.bottom {
			border-top: 0;
			.container {
				flex-direction: column;
			}
			.text {
				&:not(:last-child) {
					margin-bottom: r(12);
				}
			}
		}
		.menus {
			grid-auto-flow: row;
			grid-auto-columns: 100%;
		}
	}
}
