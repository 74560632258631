.accordion {
	padding: r(50) 0 r(60);
	margin-bottom: -2px;
	border-top: 2px solid #908fa8;
	border-bottom: 2px solid #908fa8;
	&__head {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
	}
	&__name {
		flex: 0 0 70%;
	}
	&__arrow {
		width: r(40);
		height: r(39);
		flex: 0 0 auto;
		transform: rotate(-90deg);
		stroke: #908fa8;
		transition-property: transform, stroke;
	}
	&__content {
		width: 89%;
		padding-top: r(28);
	}
	&__collapse {
		overflow: hidden;
		transition: height var(--collapse-time, #{$tr-time * 2});
		&.is-collapsed {
			height: 0;
		}
	}
	&.open {
		.accordion {
			&__arrow {
				transform: rotate(90deg);
				stroke: #e51a71;
			}
		}
	}
}

@media (max-width: 1279px) {
	.accordion {
		padding: r(20) r(20) r(20) 0;
		&__name {
			flex: 0 0 85%;
			font-size: r(14);
		}
		&__arrow {
			width: r(20);
			height: r(20);
		}
		&__content {
			width: 100%;
			padding-top: r(30);
		}
	}
}
