.in-development {
	.title {
		margin-bottom: r(90);
	}
	.slider {
		position: relative;
		.slider-arrow {
			position: absolute;
			top: r(360);
			z-index: 2;
			&.next {
				right: r(30);
			}
			&.prev {
				left: r(30);
			}
		}
		.swiper {
			overflow: visible;
			overflow-x: clip;
		}
		.swiper-slide {
			height: auto;
		}
	}
}

@media (min-width: 1280px) {
	.in-development {
		.link-to-all {
			margin-top: 0;
		}
		.slider {
			.swiper-slide {
				padding: 0 r(113);
				.project-card {
					transition: transform 0.5s;
				}
				&-active {
					padding: 0 r(7);
					.project-card {
						transform: translateY(r(-140));
					}
				}
				&:not(.swiper-slide-active) {
					.desc {
						opacity: 0;
						pointer-events: none;
					}
				}
			}
		}
	}
}

@media (max-width: 1279px) {
	.in-development {
		.title {
			margin-bottom: r(20);
		}
		.slider {
			padding: 0 21px;
			overflow: hidden;
			.swiper {
				overflow: visible;
			}
			.slider-arrow {
				top: 50%;
				&.next {
					right: r(7);
				}
				&.prev {
					left: r(7);
				}
			}
		}
	}
}
