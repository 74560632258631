.contacts-block {
	.tel {
		display: inline-block;
		margin-bottom: r(10);
		&-text {
			font-size: r(22);
			color: rgba(255, 255, 255, 0.75);
		}
	}
	.links-row {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: min-content;
		align-items: center;
		gap: r(18);
		margin-top: r(40);
		.btn {
			padding: r(14) r(16);
		}
	}
}

@media (max-width: 1279px) {
	.contacts-block {
		.tel {
			margin-bottom: r(3);
			&-text {
				font-size: r(12);
			}
		}
		.links-row {
			.soc-icon {
				width: r(40);
				height: r(40);
			}
		}
	}
}

@media (max-width: 575px) {
	.contacts-block {
		.links-row {
			gap: r(13);
			.btn {
				padding: r(8) r(16) r(10);
			}
		}
	}
}
