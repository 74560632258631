.video-review {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	.text {
		position: relative;
		padding: r(102) r(26) r(100) r(98);
		margin-bottom: r(72);
	}
	.left-col {
		flex: 0 0 36.4%;
	}
	.quote {
		width: r(82);
		height: r(64);
		position: absolute;
		fill: transparent;
		stroke: #e51a71;
		&.end {
			bottom: 0;
			right: 0;
		}
		&.start {
			top: 0;
			left: 0;
			transform: rotate(180deg);
		}
	}
	.video-wrap {
		height: r(538);
		position: relative;
		border-radius: r(20);
		box-shadow: 0px 0px 31px 0px rgba(152, 0, 231, 0.48);
		overflow: hidden;
		.play {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.icon {
				width: r(120);
				height: r(120);
			}
		}
	}
	.video {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.right-col {
		flex: 0 0 57.6%;
	}
	.open-all {
		color: #e51a71;
	}
	&.reverse {
		.left-col {
			order: 1;
		}
		.right-col {
			flex: 0 0 52%;
		}
	}
}

@media (min-width: 1280px) {
	.video-review {
		.open-all,
		.dots {
			display: none;
		}
		.hidden-text {
			display: inline !important;
		}
	}
}

@media (max-width: 1279px) {
	.video-review {
		align-items: center;
		.text {
			margin-bottom: 0;
			padding: r(41) r(18) r(25) r(49);
			font-size: r(14);
		}
		.quote {
			width: r(39);
			height: r(30);
		}
		.video-wrap {
			height: r(300);
			border-radius: r(8);
			.play {
				.icon {
					width: r(35);
					height: r(35);
				}
			}
		}
		.two-words {
			&:not(.shown) {
				color: #908fa8;
			}
		}
	}
}

@media (max-width: 767px) {
	.video-review {
		flex-direction: column;
		.video-wrap {
			height: r(170);
			flex: 0 0 auto;
		}
		.text {
			margin: r(20) r(-11) 0;
			order: 1;
		}
	}
}
