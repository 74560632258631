.page-header {
	position: relative;
	padding: r(169) 0 r(60);
	margin-bottom: r(90);
	.top-container {
		.container {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}
	.breadcrumbs {
		margin-bottom: r(51);
		flex: 0 0 100%;
	}
	&.has-text {
		min-height: r(726);
		.breadcrumbs {
			margin-bottom: r(189);
		}
		.bg {
			&__img {
				height: auto;
			}
		}
	}
	.bg {
		&__img {
			object-position: bottom;
		}
	}
	.title {
		flex: 0 0 55%;
	}
	.text-wrap {
		padding-top: r(60);
		flex: 0 0 26%;
	}
}

@media (max-width: 1279px) {
	.page-header {
		padding: 0;
		margin-bottom: r(30);
		&.has-text {
			min-height: auto;
			.breadcrumbs {
				margin-bottom: r(34);
			}
			.bg__img {
				height: 100%;
			}
		}
		.breadcrumbs {
			margin-bottom: r(34);
		}
		.top-container {
			padding: r(68) 0 r(20);
			position: relative;
			.container {
				display: block;
			}
			.bg {
				border-radius: 0 0 r(40) r(40);
				overflow: hidden;
			}
		}
		.text-wrap {
			padding-top: r(20);
		}
	}
}
