.categories-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 r(-22) r(-36) 0;
	.btn {
		margin: 0 r(22) r(36) 0;
	}
}

@media (max-width: 1279px) {
	.categories-list {
		margin: 0 r(-12) r(-12) 0;
		.btn {
			margin: 0 r(12) r(12) 0;
		}
	}
}
