.content-project-gallery {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-auto-rows: r(360);
	gap: r(30);
	--border-radius: #{r(180)};
	.content-gallery-item {
		overflow: hidden;
		&__wrap {
			height: 100%;
			padding-bottom: 0;
			border-radius: 0;
		}
		&:nth-child(1),
		&:nth-child(4) {
			grid-column: span 2;
		}
		&:nth-child(3) {
			border-radius: 0 var(--border-radius) 0 0 !important;
		}
		&:nth-child(4) {
			border-radius: 0 0 0 var(--border-radius) !important;
		}
		&:nth-child(2),
		&:nth-child(3) {
			grid-row: span 2;
		}
		&:nth-child(6) {
			border-radius: 0 var(--border-radius) 0 0 !important;
		}
		&:nth-child(7) {
			grid-column: span 2;
			grid-row: span 2;
			border-radius: 0 0 var(--border-radius) 0 !important;
		}
		&:nth-child(8) {
			border-radius: 0 0 0 var(--border-radius) !important;
		}
	}
}

@media (max-width: 1279px) {
	.content-project-gallery {
		--border-radius: #{r(20)};
		gap: r(10);
		grid-auto-rows: r(60);
	}
}
