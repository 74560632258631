.cases-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: r(80) r(60);
}

@media (max-width: 1279px) {
	.cases-grid {
		grid-template-columns: 1fr;
		gap: r(30);
	}
}
