.modal-wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
    overflow: auto;
    &:not(.open){
        display: none;
    }
    .wrap{
        position: relative;
    }
    .close{
        position: absolute;
        .icon{
            width: r(30);
            height: r(30);
            fill: #fff;
        }
    }
    @media (max-width: 1279px){
        .close{
            .icon{
                width: r(16);
                height: r(16);
            }
        }
    }
}