.documents-section {
	.documents-wrap {
		position: relative;
		margin: 0 r(-15) r(-34);
	}
	.grid-item {
		position: absolute;
		margin: 0 r(15) r(34);
	}
	.document {
		width: r(390);
		.img {
			width: 100%;
		}
	}
}

@media (min-width: 1280px) {
	.documents-section {
		.grid-item {
			&:nth-child(1),
			&:nth-child(3) {
				margin-top: r(72);
			}
		}
	}
}

@media (max-width: 1279px) {
	.documents-section {
		.documents-wrap {
			margin: r(-5);
			.grid-item {
				width: calc(50% - r(10));
				margin: r(5);
			}
		}
		.document {
			width: 100%;
		}
	}
}
