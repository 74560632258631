.content-file-row {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	position: relative;
	padding: 0 r(32);
	.icon {
		width: r(50);
		height: r(63);
		flex: 0 0 auto;
		fill: #908fa8;
	}
	.name {
		flex: 1 1 auto;
		font-size: r(20);
		line-height: 1.5;
		color: #908fa8;
	}
	.meta {
		flex: 0 0 17%;
		padding-right: r(20);
		margin-left: r(28);
		font-size: r(18);
		line-height: 1.05;
		color: #908fa8;
		transition: color $tr-time;
	}
	&:hover {
		.icon {
			fill: #fff;
		}
		.name,
		.meta {
			color: #fff;
		}
	}
	&:not(:last-child) {
		margin-bottom: r(30);
	}
}

@media (max-width: 1279px) {
	.content-file-row {
		padding: 0;
		.icon {
			width: r(25);
			height: r(31);
		}
		.meta {
			flex: 1 0 auto;
			margin-left: r(8);
			font-size: r(14);
		}
		.name {
			margin-top: r(10);
			flex: 0 0 100%;
			font-size: r(14);
		}
		&:not(:last-child) {
			margin-bottom: r(18);
		}
	}
}
