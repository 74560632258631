.mobile-menu {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9;
	background: #000;
	&:not(.open) {
		display: none;
	}
	.wrap {
		height: 100%;
		padding: r(117) 0 r(30);
		overflow: auto;
	}
	.bg {
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(28, 28, 28, 0.6);
		}
	}
	.menu {
		.submenu {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			overflow: auto;
			z-index: 1;
			.wrap {
				padding: r(76) 0 r(46);
			}
			&:not(.open) {
				display: none;
			}
			.link {
				color: #d3d3de;
				font-size: r(12);
			}
			li {
				padding-left: r(20);
				&:not(:last-child) {
					margin-bottom: r(8);
				}
			}
			.back {
				display: flex;
				align-items: center;
				font-size: r(14);
				margin-bottom: r(20);
				.arr {
					margin: 0 r(8) 0 0 !important;
					transform: rotate(90deg) !important;
				}
			}
		}
		.link {
			font-size: r(14);
		}
		.item {
			&.parent {
				display: flex;
				align-items: center;
				.arr {
					display: none;
					width: r(9);
					height: r(5);
					margin-left: r(8);
					transform: rotate(-90deg);
					fill: #fff;
				}
			}
			&:not(:last-child) {
				margin-bottom: r(20);
			}
		}
		.parent-name {
			margin-bottom: r(24);
			color: #e51a71;
			font-size: r(20);
			font-weight: 500;
		}
		.group-col {
			&:not(:last-child) {
				margin-bottom: r(30);
			}
		}
		.group-name {
			margin-bottom: r(16);
			font-size: r(12);
			text-decoration-line: underline;
			text-transform: uppercase;
		}
	}
}
