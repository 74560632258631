.content-project-slider {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.swiper-col,
	.text-col {
		flex: 0 0 auto;
	}
	.text-col {
		width: 43.1%;
	}
	.swiper-col {
		width: 49.1%;
	}
	&.single-col {
		.swiper-col,
		.text-col {
			width: 100%;
		}
		.text-col {
			margin-top: r(40);
		}
		.swiper-slide {
			padding-bottom: 41.2%;
		}
	}
	&.text-left {
		.swiper-col {
			order: 1;
		}
	}
	&.text-left,
	&.text-right {
		.step-title {
			margin-left: r(11);
		}
	}
	&.center {
		.text-col {
			width: 100%;
		}
		.step-title,
		.goal {
			text-align: center;
		}
	}
	.cert-col {
		display: flex;
		justify-content: center;
		width: 100%;
		order: 1;
		margin: r(60) 0 0;
	}
	.title-row {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: r(40);
		flex: 0 0 100%;
		.title {
			flex: 0 0 32%;
		}
		.name {
			color: #908fa8;
		}
	}
	.goal {
		margin-top: r(20);
		color: #908fa8;
	}
	.step-row {
		margin-top: r(40);
	}
	.swiper {
		border-radius: r(20);
		overflow: hidden;
	}
	.swiper-slide {
		padding-bottom: 84%;
	}
	.slide-img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
	}
	.slider-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		&.prev {
			left: 0;
			transform: translate(-50%, -50%) rotate(180deg);
		}
		&.next {
			right: 0;
			transform: translate(50%, -50%);
		}
	}
	.text-col {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.slider-pagination {
		position: absolute;
		left: 0;
		right: 0;
		bottom: r(22);
		z-index: 1;
	}
	.cert {
		width: r(390);
		max-width: 100%;
	}
}

@media (max-width: 1279px) {
	.content-project-slider {
		&.single-col {
			.text-col {
				margin-top: r(24);
			}
		}
		.title-row {
			margin-bottom: r(24);
		}
		.swiper {
			border-radius: r(8);
		}
		.goal {
			margin-top: r(8);
		}
		.cert {
			width: r(250);
		}
		.cert-col {
			margin-top: r(30);
		}
	}
}

@media (max-width: 767px) {
	.content-project-slider {
		&.text-left,
		&.text-right {
			.step-title {
				margin-left: 0;
			}
		}
		&.single-col {
			.swiper-slide {
				padding-bottom: 84%;
			}
		}
		.swiper-col {
			width: 94% !important;
			margin: 0 auto;
		}
		.text-col {
			margin-top: r(20) !important;
			order: 2;
		}
		.title-row {
			flex-direction: column;
			align-items: flex-start;
			.name {
				margin-top: r(8);
			}
		}
		.text-col {
			width: 100%;
		}
	}
}
