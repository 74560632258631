@font-face {
	font-family: "Geologica";
	src: url("../fonts/Geologica_Auto-Bold.ttf") format("truetype");
	font-weight: 700;
}
@font-face {
	font-family: "Geologica";
	src: url("../fonts/Geologica_Auto-SemiBold.ttf") format("truetype");
	font-weight: 600;
}
@font-face {
	font-family: "Geologica";
	src: url("../fonts/Geologica_Auto-Medium.ttf") format("truetype");
	font-weight: 500;
}
@font-face {
	font-family: "Geologica";
	src: url("../fonts/Geologica_Auto-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "Geologica";
	src: url("../fonts/Geologica_Auto-Light.ttf") format("truetype");
	font-weight: 300;
}
