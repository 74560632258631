.categories-dropdown {
	position: relative;
	z-index: 1;
	background: #110f1d;
	.arr {
		width: r(9);
		height: r(5);
		flex: 0 0 auto;
		margin-left: r(10);
		fill: #fff;
		transition: transform $tr-time;
	}
	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: r(9) r(11);
		font-size: r(12);
		font-weight: 500;
		line-height: 1.5;
		border: 2px solid rgba(255, 255, 255, 0.41);
		border-radius: r(26);
	}
	.dropdown {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		border-radius: 0 0 r(26) r(26);
		border-color: rgba(255, 255, 255, 0.41);
		border-style: solid;
		border-width: 0 2px 2px;
		background: #110f1d;
		.group {
			padding: r(15) r(16);
			.name {
				font-size: r(12);
				&:not(:last-child) {
					margin-bottom: r(16);
				}
			}
			.link {
				display: block;
				font-size: r(10);
				color: #908fa8;
				&:not(:last-child) {
					margin-bottom: r(8);
				}
			}
			&:not(:last-child) {
				border-bottom: 2px solid rgba(255, 255, 255, 0.41);
			}
		}
	}
	&.open {
		.top {
			border-radius: r(26) r(26) 0 0;
		}
		.arr {
			transform: rotate(180deg);
		}
	}
}
