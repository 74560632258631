.content-image {
	img {
		max-width: 100%;
		border-radius: r(20);
		margin: 0 auto;
	}
}

@media (max-width: 1279px) {
	.content-image {
		img {
			border-radius: r(8);
		}
	}
}
