.school-modal{
    padding: 10vh 0 5vh;
    --form-border-color: rgba(255, 255, 255, 0.41);
	--form-placeholder-color: #fff;
	--form-text-color: #fff;
	--form-personal-color: #908fa8;
	--form-personal-link-color: #fff;
	--form-status-icon-color: #fff;
	--form-error-color: red;
	--form-status-scale: 0.67;
    .wrap{
        margin: 0 auto;
        width: 92%;
        max-width: 1458px;
        padding: r(80);
        border: 2px solid rgba(#fff, .41);
        border-radius: 20px;
        overflow: hidden;
    }
    .title{
        margin-bottom: r(24);
    }
    .text{
        margin-bottom: r(40);
        font-size: r(20);
    }
    .form-wrap{
        width: 70%;
    }
    .close{
        top: r(72);
        right: r(57);
    }
    .form{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: r(20);
        .comment-field{
            grid-column: span 2;
            grid-row: span 2;
        }
        .personal{
            grid-column: span 2;
        }
    }

    @media (max-width: 1279px) {
        .wrap{
            padding: r(30);
        }
        .title{
            margin-bottom: r(14);
        }
        .text{
            margin-bottom: r(20);
            font-size: r(12);
        }
        .close{
            top: r(20);
            right: r(20);
        }
        .form{
            gap: r(10);
        }
    }
    @media (max-width: 767px){
        .form{
            grid-template-columns: 1fr;
            .comment-field{
                height: r(100);
                grid-column: auto;
                grid-row: auto;
            }
            .personal{
                grid-column: auto;
            }
        }
    }
    @media (max-width: 575px){
        .form-wrap{
            width: 100%;
        }
    }
}