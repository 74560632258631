.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	padding: r(24) 0;
	.bg {
		transition: opacity $tr-time;
	}
	&:not(.is-sticky) {
		.bg {
			opacity: 0;
		}
	}
	.container {
		display: flex;
	}
	.btn-wrap {
		margin-left: auto;
		.text {
			margin-top: r(10);
			font-size: r(16);
			font-weight: 300;
			color: #908fa8;
			text-align: center;
		}
	}
	.menu {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: max-content;
		justify-content: center;
		gap: r(40);
		flex: 1 1 auto;
		padding: 0 r(30);
		.item {
			display: flex;
			align-items: center;
			&.parent {
				&:hover {
					.arr {
						transform: rotate(180deg);
						fill: #e51a71;
					}
					.submenu {
						// display: block;
					}
				}
			}
		}
		.link {
			font-size: r(22);
		}
		.arr {
			display: none;
			width: r(15);
			height: r(10);
			margin-left: r(8);
			fill: #fff;
			transition-property: transform, fill;
		}
		.submenu {
			display: none;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			z-index: -1;
			padding: r(270) 0 r(186);
			min-height: r(726);
			.bg {
				&::after {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background: rgba(28, 28, 28, 0.6);
				}
			}
		}
		.submenu-grid {
			display: grid;
			grid-template-columns: repeat(2, max-content);
			gap: r(12) r(60);
			.link {
				color: #d3d3de;
			}
		}
		.group-grid {
			display: grid;
			grid-template-columns: repeat(2, max-content);
			gap: r(129);
		}
		.group-name {
			display: inline-block;
			margin-bottom: r(44);
			font-size: r(22);
			line-height: 0.8;
			text-transform: uppercase;
			border-bottom: 1px solid #fff;
		}
	}
	.logo {
		position: relative;
		align-self: flex-start;
		.img {
			width: r(82);
			height: r(82);
		}
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: -1;
			width: r(152);
			height: r(152);
			transform: translate(-50%, -50%) scale(0);
			background: radial-gradient(50% 50% at 50% 50%, rgba(229, 26, 113, 0.85) 0%, rgba(229, 26, 113, 0) 100%);
			transition: transform $tr-time;
		}
		&:hover {
			&::after {
				transform: translate(-50%, -50%);
			}
		}
	}
	.mob-phone {
		display: flex;
		align-items: center;
		margin-left: auto;
		font-weight: 500;
		.icon {
			width: r(18);
			height: r(18);
			margin-left: r(11);
		}
		@media (min-width: 1280px) {
			display: none;
		}
	}
	.menu-open {
		.burger {
			width: r(16);
			height: r(7);
			fill: #fff;
		}
		.cross {
			display: none;
			width: r(12);
			height: r(12);
			fill: #fff;
		}
		&.open {
			.burger {
				display: none;
			}
			.cross {
				display: block;
			}
		}
	}
	&.menu-opened {
		.logo {
			display: none;
		}
	}
}

@media (max-width: 1279px) {
	.header {
		display: flex;
		align-items: center;
		padding: r(10) 0;
		height: r(60);
		.menu,
		.btn-wrap {
			display: none;
		}
		.logo {
			margin-right: r(18);
			.img {
				height: r(40);
				width: r(40);
			}
		}
	}
}
